import { withDependencies, named } from '@wix/thunderbolt-ioc'
import {
	PageFeatureConfigSymbol,
	IPageWillMountHandler,
	StructureAPI,
	IStructureAPI,
	IPropsStore,
	Props,
} from '@wix/thunderbolt-symbols'
import { SospPageConfig } from './types'
import { name } from './symbols'

const sospFactory = (
	structureApi: IStructureAPI,
	propsStore: IPropsStore,
	pageFeatureConfig: SospPageConfig
): IPageWillMountHandler => {
	return {
		async pageWillMount(pageId) {
			const { compId, parentId, position, pagesToShow } = pageFeatureConfig
			const { components } = structureApi.get(parentId)
			if (pagesToShow.includes(pageId)) {
				if (!components.includes(compId)) {
					structureApi.addComponentToParent(parentId, compId, position)
				}
				propsStore.update({ [parentId]: { className: 'page-with-sosp' } })
			} else {
				if (components.includes(compId)) {
					structureApi.removeComponentFromParent(parentId, compId)
				}
				propsStore.update({ [parentId]: { className: 'page-without-sosp' } })
			}
		},
	}
}

export const Sosp = withDependencies([StructureAPI, Props, named(PageFeatureConfigSymbol, name)], sospFactory)
